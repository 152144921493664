
import Vue from 'vue';
import axios from 'axios';

interface IRecord{
  deleted: number,
  desc: string,
  id: number,
  items:{id:number, number:number}[],
  payType: 'wechat' | 'alipay' | 'cash',
  time: string,
  totalPrice: number,
  openid: string,
  submitTime: string,
  updateTime: string,
}

interface IItemSummary{
  id:number,
  name:string,
  number:number,
  price:number,
  totalPrice:number,
  wechat:number,
  alipay:number,
  cash:number,
}

export default Vue.extend({
  name: 'App',

  components: {
  },

  data: () => ({
    //
    items: [
      { id:0, person:0, name: "《橘色仲夏》", type: "本", price: 40, sellType: "出售", stock: 170, desc: "来买本的给一个包装袋"},
      { id:5, person:1, name: "龙娘oc(3张一套)", type: "明信片", sellType: "出售/无料交换", price: 6,stock: 25, desc: "" },
      { id:6, person:1, name: "武士/镰刀(2张一套)", type: "明信片", sellType: "出售/无料交换", price: 4, stock: 30, desc: "" },
      { id:7, person:1, name: "阿莉塞/阿尔菲诺 明信片", type: "明信片", sellType: "出售", price: 3, stock: 30, desc: "" },
      { id:8, person:1, name: "梅蒂恩 明信片", type: "明信片", sellType: "出售", price: 3, stock: 70, desc: "" },
      { id:13, person:2, name: "水晶公挂件", type: "亚克力挂件", price: 5, sellType: "出售/无料交换", stock: 20, desc: "" },
      { id:14, person:2, name: "拂晓猫挂件", type: "亚克力挂件", price: 5, sellType: "出售/无料交换", stock: 20, desc: "" },
      { id:15, person:2, name: "古拉哈透卡", type: "透卡", price: 3, sellType: "出售/无料交换", stock: 70, desc: "" },
      { id:16, person:2, name: "小红猫徽章", type: "透卡", price: 10, sellType: "出售/购入送/无料交换", stock: 50, desc: "" },

    ],
    loading: false,
    record: [] as IRecord[],
    payTypeName:{
      wechat:'微信',
      alipay:'支付宝',
      cash:'现金',
    },
    headers: [
      { text: 'ID', value: 'id', },
      {text:'时间', value:'time'},
      { text: '备注', value: 'desc', },
      { text: '支付方式', value: 'payType', },
      { text: '金额', value: 'totalPrice', },
    ],
    headers2: [
      { text: '名称', value: 'name', },
      {text:'售出数量', value:'number'},
      { text: '总收入', value: 'totalPrice', },
      { text: '微信', value: 'wechat', },
      { text: '支付宝', value: 'alipay', },
      { text: '现金', value: 'cash', },
    ],
    
    
    itemSum:[] as IItemSummary[],
  }),
  methods:{
    getData(){
      this.loading = true;
      axios.get('https://aaa.nightship.cn/api/getAll2.php').then((res) => {
        console.log(res);
        if (res.status === 200) {
          if(res.data.status == 'success')
          {
            this.record = res.data.data;
            this.itemSummary(res.data.data);
          }
          else{
            window.alert(res.data.msg);
          }  
        }
      })
      .finally(() => {
        this.loading = false;
      })
    },
    getItemById(id:number){
      return this.items.find((item) => item.id == id);
    },

    itemSummary(r:IRecord[]){
      const summary:IItemSummary[] = this.items.map((item) => {
        return {
          id:item.id,
          name:item.name,
          number:0,
          price:item.price,
          totalPrice:0,
          wechat:0,
          alipay:0,
          cash:0,
        }
      });

      r.forEach((record) => {
        record.items.forEach((item) => {
          const index = summary.findIndex((i) => i.id == item.id);
          summary[index].number += item.number;
          summary[index].totalPrice += item.number * summary[index].price;
          summary[index][record.payType] += item.number * summary[index].price;
        });
      });

      this.itemSum = summary;
    }
  },
  mounted() {
    //
    this.getData();
  },
});
